<template>
  <div>

    <nav-bar>

      <b-navbar-nav v-if="menutop">
        <b-nav-item>
          <router-link to="/newsletter/criar">
          <b-icon icon="envelope" font-scale="2.5"></b-icon><br>
          Enviar E-mail
          </router-link>
        </b-nav-item>

        <b-nav-item>
          <router-link to="/newsletter/listar">
          <b-icon icon="list" font-scale="2.5"></b-icon><br>
          Listar E-mails
          </router-link>
        </b-nav-item>

        <b-nav-item>
          <router-link to="/consultas/listar">
          <b-icon icon="journal-code" font-scale="2.5"></b-icon><br>
          Consultas SQL
          </router-link>
        </b-nav-item>

        <b-nav-item>
          <router-link to="/#">
          <b-icon icon="pie-chart" font-scale="2.5"></b-icon><br>
          Estatísticas
          </router-link>
        </b-nav-item>

        <b-nav-item>
          <router-link to="/#">
          <b-icon icon="calendar3" font-scale="2.5"></b-icon><br>
          Agendamentos
          </router-link>
        </b-nav-item>

        <b-nav-item>
          <a v-on:click="sair()">
          <b-icon icon="door-open" font-scale="2.5"></b-icon><br>
          Sair
          </a>
        </b-nav-item>
      </b-navbar-nav>
<!--
      <b-nav-item style="width: 30px">
        <b-img :src="usuario.imagem" alt="" fluid rounded="circle"></b-img>
        <br>
        {{usuario.name}}
      </b-nav-item>      
-->

    </nav-bar>

    <b-container class="bv-example-row" style="margin-top: 5%">
      <b-row class="text-center">
        <b-col cols="1"></b-col>
        <b-col cols="10"><slot></slot></b-col>
        <b-col cols="1"></b-col>
      </b-row>
    </b-container>
    
  </div>
</template>

<script>

import NavBar from '@/components/NavBar'

export default {
  name: 'HomeTpl',
  data(){
    return {
      menutop: false,
      usuario: false
    }
  },
  
  components:{
    NavBar,
  },

  created(){
    //console.log(this.$router.currentRoute.path);

    if( this.$router.currentRoute.path != '/home' ) {
      this.menutop = true;
    } 
    
    let usuarioAux = this.$store.getters.getUsuario;
    if(usuarioAux){
      this.usuario = this.$store.getters.getUsuario;
    }else{
      this.$router.push('/');
    }
  },

  methods:{
    sair(){
      this.$store.commit('setUsuario',null);
      sessionStorage.clear();
      this.usuario = false;
      this.$router.push('/');
    }
  }

}


</script>
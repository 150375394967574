<template>
  <home-tpl>
    <b-card
      title=""
      class=" mx-auto"
      
      align-v="center">
    <b-container class="bv-example-row" style="">
      <b-row class="text-center">
        <b-col cols="12">
          <b-form @submit="cadastrar">

            <b-form-input
              id="input-1"
              v-model="assunto"
              type="text"
              required
              placeholder="Assunto"
            ></b-form-input>

            <b-form-group id="input-group-2" class="mt-3" label="" label-for="input-2">
              <b-form-select
                id="input-2"
                v-model="parametros"
                :options="consultas"
                required
              ></b-form-select>
            </b-form-group>
            
            <vue-editor 
            useCustomImageHandler
            @image-added="handleImageAdded" 
            v-model="content">
            </vue-editor>
              
              <br>
              <b-button type="submit" variant="primary">Próximo</b-button>

          </b-form>          
        </b-col>
      </b-row>
    </b-container>          

    </b-card>



  </home-tpl>
</template>

<script>
// @ is an alias to /src
import HomeTpl from '@/tpls/HomeTpl.vue'
import {VueEditor} from 'vue2-editor'


export default {
  name: 'Criar',
  data() {
    return {
      assunto: '',
      content: '',
      parametros: null,
      consultas: [{ 
        text: 'Selecionar Contatos', 
        value: null
      },
      {
        text: 'TI E MARKETING', 
        value: '1'
      }],
        
    }
  },

components: {
    HomeTpl,
    VueEditor,
  },
  
  methods:{
    
    async handleImageAdded(file, Editor, cursorLocation) {
      
      const formData = new FormData();
      formData.append('image', file)
      
      this.$http.post(this.$urlApiImg, formData, {
        
        headers:{
          'Authorization': 'Client-ID f6438aaddf24a82'
        }        
      })
      

      /*
      axios({
        url: 'https://api.imgur.com/3/image',
        method: 'POST',
        headers:{
          'Authorization': 'Client-ID ' + CLIENT_ID
        },
        data: formData
      })

      */
      .then((result) => {
        console.log(result);
        let url = result.data.data.link
        Editor.insertEmbed(cursorLocation, 'image', url);
      })
      .catch((err) => {
        console.log(err);
      })
    },


    cadastrar(evt) {
      evt.preventDefault()
      console.log(this.assunto);
      this.$http.post(this.$urlApi+'newsletter/cadastrar', {
        assunto: this.assunto,
        parametros: this.parametros,
        conteudo: this.content,
      },

     {"headers": {"authorization": "Bearer "+this.$store.getters.getToken}}) 

      .then(response => {
        //console.log(response)
        if(response.data.status){

          //console.log(response.data.newsletter);

          this.$router.push('/newsletter/ver/'+response.data.newsletter.id);

        }else if(response.data.status == false && response.data.validacao){

          // erros de validação
          console.log('erros de validação')
          let erros = '';
          for(let erro of Object.values(response.data.erros)){
            erros += erro +" ";
          }
          alert(erros);

        }else{

          //login não existe
          alert('Erro no cadastro! tente novamente mais tarde.');
        }
      })
      
      .catch(e => {
        console.log(e)
        alert("Erro! Tente novamente mais tarde!");
      })  
      
    },



  }
  
}

</script>


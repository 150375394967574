<template>
  <div>

    <b-navbar toggleable="lg" type="dark"  class="bd-navbar">
      <b-navbar-brand href="http://192.168.15.10:8080/home">
          <b-img
            src="https://donaclara.com.br/site/www/app-assets/images/logo.png"
            width="140%"
            height="80%"
            >
          </b-img>      
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <slot />
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

  </div>    
</template>

<script>
export default {
  name: 'NavBar',

}
</script>